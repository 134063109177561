import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { SortingRule } from 'react-table';
import { Link } from 'react-router-dom';
import { CardRow } from './CardRow';
import { getQuantityLabel } from '../ProductQuantityLabel';
import { CoverImage } from '../../../../components/CoverImage';
import { Logo } from '../../../../components/Logo';
import { PageCountPicker } from '../../../../components/PageCountPicker';
import { useAuth } from '../../../Auth/hooks/useAuth';
import { Restricted } from '../../../Auth/components/Restricted';
import { HeartProducts } from '../../../Marketplace/components/HeartProducts';
import { useTableSelectedProducts } from '../SelectedProductsContext';

import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import IProduct from '../../../../types/IProductType';
import { IImageMediaGroup } from '../../../../types/IMediaType';
import {
    ITableAction,
    PAGE_SIZE_CHANGED,
} from '../../../../hooks/useTableReducer';

import './ProductMobileView.scss';

const CardImage = ({
    product,
    isMarketPlace,
}: {
    product: IProduct;
    isMarketPlace?: boolean;
}) => (
    <div className='position-relative'>
        <CoverImage
            maxSize
            images={product?.product_images as IImageMediaGroup}
            lightbox
        />
        {isMarketPlace && (
            <div className='d-none d-sm-block position-absolute top-0 start-0 ps-3 pt-2'>
                <HeartProducts product={product} />
            </div>
        )}
        <div className='position-absolute top-0 end-0 pe-1 pt-2'>
            <Logo
                shape='circle'
                size='xs'
                shadow='shadow-sm'
                images={product?.organisation?.organisation_logo}
            />
        </div>
    </div>
);

interface CardProps {
    product: ResourcePermissionsType<IProduct> | IProduct;
    organisation: any;
    isMarketPlace?: boolean;
    selected: boolean;
    toggle: () => void;
}

const ProductMobileCard = ({
    product,
    organisation,
    isMarketPlace,
    selected,
    toggle,
}: CardProps) => {
    const { t } = useTranslation();

    const priceStringFor = (product: IProduct) => {
        if (!product?.quantity) return t('Sold out');
        if (product?.sales_price) {
            let rv = `${product.sales_price} kr/${product.category?.unit?.symbol}`;

            if (product?.comparison_price && product?.comparison_price_unit) {
                rv += ` (${product.comparison_price} kr/${product.comparison_price_unit.symbol})`;
            }

            return rv;
        }
        return t('Price upon request');
    };

    return (
        <div className='p-2 d-flex flex-column'>
            <Row className='mb-1'>
                <Col xs={5} className='ps-4'>
                    <CardImage
                        product={product}
                        isMarketPlace={isMarketPlace}
                    />
                </Col>
                <Col
                    xs={7}
                    className='d-flex flex-column justify-content-center'
                >
                    <Row className='align-items-center'>
                        <Col xs={9}>
                            <h5 className='fw-bold text-break'>
                                {product.title}
                            </h5>
                        </Col>
                        <Col xs={3}>
                            {!isMarketPlace && (
                                <Form.Check
                                    type='checkbox'
                                    checked={selected}
                                    onChange={() => toggle()}
                                />
                            )}
                        </Col>
                    </Row>
                    <CardRow
                        title={t('Quantity')}
                        value={getQuantityLabel(product)}
                    />
                    <CardRow
                        title={t('Price')}
                        value={priceStringFor(product)}
                    />
                    <CardRow title={t('Barcode')} value={product.barcode} />
                </Col>
            </Row>

            <Row>
                <Col className='mb-1'>
                    {isMarketPlace ? (
                        <Link
                            className='btn btn-primary w-100'
                            to={`/products/${product.id}`}
                        >
                            {t('Show')}
                        </Link>
                    ) : (
                        <Restricted
                            to='view single product'
                            resource={
                                product as ResourcePermissionsType<IProduct>
                            }
                        >
                            <Link
                                className='btn btn-primary w-100'
                                to={`/admin/products/${product.id}`}
                            >
                                {t('Show')}
                            </Link>
                        </Restricted>
                    )}
                </Col>
                {product.organisation_id === organisation?.id && (
                    <Col className='mb-1'>
                        <Restricted
                            to='edit single product'
                            resource={
                                product as ResourcePermissionsType<IProduct>
                            }
                        >
                            <Link
                                className='btn btn-secondary w-100'
                                to={`/admin/projects/${product?.project_id}/product-form/${product.id}`}
                            >
                                {t('Edit')}
                            </Link>
                        </Restricted>
                    </Col>
                )}
            </Row>
        </div>
    );
};

interface Props {
    products: ResourcePermissionsType<IProduct>[];
    queryPageIndex?: number;
    queryPageSize?: number;
    sortBy?: SortingRule<string>;
    totalCount?: number;
    dispatch?: React.Dispatch<ITableAction>;
    loading?: boolean;
    elevatedActions?: boolean;
    isMarketPlace?: boolean;
}

export const ProductMobileView = ({
    products,
    queryPageSize,
    totalCount,
    dispatch,
    isMarketPlace,
}: Props) => {
    const { organisation } = useAuth();
    // available in /admin/products, not in marketplace
    const { selectedItems, setSelectedItems } = useTableSelectedProducts();

    function toggle(product: ResourcePermissionsType<IProduct>) {
        setSelectedItems((prev) => {
            if (prev.includes(product)) {
                return prev.filter((item) => item.id !== product.id);
            }
            return [...prev, product];
        });
    }

    return (
        <Stack gap={3}>
            <Stack id='product-mobile-view' className='overflow-hidden'>
                {products.map((product) => (
                    <ProductMobileCard
                        key={product.id}
                        product={product}
                        organisation={organisation}
                        selected={
                            !isMarketPlace &&
                            (selectedItems || []).includes(product)
                        }
                        toggle={() => toggle(product)}
                        isMarketPlace={isMarketPlace}
                    />
                ))}
            </Stack>
            <PageCountPicker
                className='d-flex justify-content-center'
                pageSize={queryPageSize || 0}
                onChangePageSize={(size) => {
                    dispatch &&
                        dispatch({
                            type: PAGE_SIZE_CHANGED,
                            payload: size,
                        });
                }}
                totalCount={totalCount || 0}
            />
        </Stack>
    );
};
