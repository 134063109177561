import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import IServerResponseType from '../../../../types/IServerResponseType';
import ResourcePermissionsType from '../../../../types/ResourcePermissionsType';
import { useAuth } from '../../../Auth/hooks/useAuth';
import { useProductFormState } from './ProductFormState';
import IProduct from '../../../../types/IProductType';
import axios from 'axios';
import { IProductItem } from '../../../../types/IProductItem';
import IProductEnvironmentData from '../../../../types/IProductEnvironmentDataType';
import { IMessurementType } from '../../../../types/IMessurementType';
import { createImages } from '../../../../API/product/images';
import { createDocuments } from '../../../../API/product/documents';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
interface Props {
    setSetProductItems: (
        func: (prev: any) => (items: IProductItem[]) => void
    ) => void;
    setSetProductEnvironmentData: (
        func: (prev: any) => (items: IProductEnvironmentData) => void
    ) => void;
}
export const SaveProductButton = ({
    setSetProductItems,
    setSetProductEnvironmentData,
}: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { organisation } = useAuth();

    const product = useProductFormState();

    // PlacementsForm will fill up this state with product items via the delegate
    const [productItemsFromPlacementsForm, setProductItemsFromPlacementsForm] =
        useState<IProductItem[]>([]);
    useEffect(() => {
        setSetProductItems((prev) => {
            return (items: IProductItem[]) => {
                prev && prev(items); // If having multiple SaveProductButtons, we need to call all with the placement items (So they all have them when Saving)
                setProductItemsFromPlacementsForm(items);
            };
        });
    }, [setProductItemsFromPlacementsForm, setSetProductItems]);
    const [productEnvironmentDataFromForm, setProductEnvironmentDataFromForm] =
        useState<IProductEnvironmentData>();
    useEffect(() => {
        setSetProductEnvironmentData((prev) => {
            return (item: IProductEnvironmentData) => {
                prev && prev(item); // If having multiple SaveProductButtons, we need to call all with the placement items (So they all have them when Saving)
                setProductEnvironmentDataFromForm(item);
            };
        });
    }, [setProductEnvironmentDataFromForm, setSetProductEnvironmentData]);

    const [, createNewProduct] = useAxios<
        IServerResponseType<ResourcePermissionsType<IProduct>>
    >(
        {
            url: `/api/organisations/${organisation?.id}/projects/${product.project_id}/products`,
            method: 'post',
        },
        { manual: true }
    );
    const lastEle = (arr?: any[]) => {
        if (!arr || !arr.length) return null;
        return arr[arr.length - 1];
    };

    const save = useCallback(async () => {
        const productItems: IProductItem[] = productItemsFromPlacementsForm.map(
            (productItem) => ({
                view_status: productItem.view_status ? 1 : 0,
                dismantle_date: new Date(0).toISOString(),
                delivery_date: productItem.delivery_date,
                product_status_id: productItem.status?.id,
                placement_id: lastEle(productItem.placementLevel)?.value,
                quantity: productItem.quantity,
                description: productItem.description,
            })
        );
        const productEnvironmentData: IProductEnvironmentData[] =
            productEnvironmentDataFromForm
                ? [productEnvironmentDataFromForm]
                : [];

        const productMessurement: IMessurementType[] = product.messures
            ? [
                  {
                      ...product.messures,
                  },
              ]
            : [];

        const productMeta: any[] = product?.product_meta;

        const productProperies: any[] = product?.productProperies.map((p) => ({
            ...p,
            property_id: p.property_id,
            value: p.value,
        }));

        const productPoints: any[] = product?.points.map((p) => ({
            ...p,
        }));

        const productData = {
            project_id: product.project_id,
            organisation_id: organisation?.id,
            category_id: product.category_id,

            title: product.title,
            description: product.description,
            manufacture_code: product.manufacture_code,
            model_code: product.model_code,
            year: product.year,

            package_quantity: product.package_quantity,
            package_unit: product.package_unit,
            internal_value: product.internal_value,
            market_price: product.market_price,
            sales_price: product.sales_price,
            search_words: product.search_words,
            condition_points: product.condition_points,
            function_points: product.function_points,
            unused: product.unused,

            weight: product.weight,
            weight_unit_id: product.weight_unit_id,

            comparison_price: product.comparison_price,
            comparison_price_unit_id: product.comparison_price_unit_id,

            video_url: product.video_url,
            video_description: product.video_description,

            ProductItemInputArray: productItems,

            ...(productEnvironmentDataFromForm
                ? { ProductEnvironmentDataInputArray: productEnvironmentData }
                : {}),

            MessurementInputArray: productMessurement,
            ProductMetaInputArray: productMeta,
            ProductPropertyInputArray: productProperies,
            ProductPointInputArray: productPoints,
        };

        // POST product
        const productResponse = await createNewProduct({
            data: productData,
        });

        if (productResponse && productResponse.data) {
            const createdProduct = productResponse.data.data;

            // POST productpoints
            const productpointsData = product.points
                ? Object.keys(product.points).map((key: any) => ({
                      title: key,
                      description: 'empty',
                      points: product.points[key],
                  }))
                : [];

            if (
                createdProduct.product_points &&
                createdProduct.product_points.length <= 0 &&
                productpointsData.length > 0
            ) {
                productpointsData.map(async (point: any) => {
                    const productpointsResponse = await axios.post<
                        IServerResponseType<ResourcePermissionsType<any>>
                    >(
                        `/api/organisations/${organisation?.id}/projects/${product.project_id}/products/${createdProduct.id}/productpoints`,
                        point
                    );

                    if (productpointsResponse) {
                    }
                });
            }

            // POST productImages
            if (
                organisation?.id &&
                product.project_id &&
                createdProduct.id &&
                product.productImages.length > 0
            ) {
                const imagesResponse = await createImages(
                    organisation?.id,
                    product.project_id,
                    createdProduct.id,
                    product.productImages
                );

                if (imagesResponse) {
                }
            }

            // POST product_documents
            if (
                organisation?.id &&
                product.project_id &&
                createdProduct.id &&
                product.productDocuments.length > 0
            ) {
                const documentsResponse = await createDocuments(
                    organisation?.id,
                    product.project_id,
                    createdProduct.id,
                    product.productDocuments
                );

                if (documentsResponse) {
                }
            }

            return createdProduct;
        }
    }, [
        createNewProduct,
        organisation?.id,
        product.category_id,
        product.description,
        product.internal_value,
        product.manufacture_code,
        product.messures,
        product.model_code,
        product.package_quantity,
        product.package_unit,
        product.points,
        product.market_price,
        product.comparison_price,
        product.comparison_price_unit_id,
        product.productDocuments,
        product.productImages,
        product?.productProperies,
        product.project_id,
        product.sales_price,
        product.search_words,
        product.condition_points,
        product.function_points,
        product.unused,
        product.title,
        product.video_description,
        product.video_url,
        product.weight,
        product.weight_unit_id,
        product.year,
        product.product_meta,
        productItemsFromPlacementsForm,
        productEnvironmentDataFromForm,
    ]);

    return (
        <Button
            variant='secondary'
            className='flex-grow-1'
            onClick={() => {
                save().then((product) => {
                    if (product) {
                        navigate(
                            '/admin/projects/' +
                                product.project_id +
                                '/product-form/' +
                                product.id
                        );
                    }
                });
            }}
        >
            {t('Save')}
        </Button>
    );
};
