import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Row, Col, Stack } from 'react-bootstrap';
import { TableOfContents, Section } from './components/TableOfContents';
import { Page } from '../../../components/Layout/Page';
import { useTranslation } from 'react-i18next';
import { SEO } from '../../../components/SEO';
import regions from '../../../utils/se_regions';
import { ReactComponent as EmailIcon } from '../../../assets/icones/mailbox.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icones/telephone.svg';
import { getStaticPage } from '../../../API/staticPages';
import timoImage from '../../../assets/images/timo_kahisalo.png';

const CONTACT_EMAIL = 'info@dacke.online';
const nl = '\n';

type ContactFormType = {
    name: string;
    phone?: string;
    region?: string;
    message: string;
};

type Data = {
    team: {
        name?: string;
        image?: string;
        position?: string;
        email?: string;
        phone?: string;
    }[];
    address: {
        email?: string;
        phone?: string;
        street?: string;
        name?: string;
        mapSrc?: string;
    };
};

const ContactForm = () => {
    const { t } = useTranslation();
    const initState: ContactFormType = {
        name: '',
        phone: '',
        region: '',
        message: '',
    };
    const [state, setState] = useState<ContactFormType>(initState);

    const onChange =
        (key: 'name' | 'phone' | 'region' | 'message') => (e: any) => {
            let newVal;
            switch (key) {
                case 'name':
                    newVal = e.target.value;
                    break;
                case 'phone':
                    newVal = e.target.value;
                    break;
                case 'region':
                    newVal = e.target.value;
                    break;
                case 'message':
                    newVal = e.target.value;
                    break;
                default:
                    break;
            }
            setState({ ...state, [key]: newVal });
        };

    const genBody: (state: ContactFormType) => string = ({
        name,
        phone,
        region,
        message,
    }) =>
        encodeURIComponent(
            `Namn: ${name}${nl}Telefon: ${phone}${nl}${
                region ? `Region: ${region}` : ''
            }${nl}Meddelande: ${message}`
        );

    const onSubmit = () => {
        const subject = encodeURIComponent('Dacke.online fråga');
        const body = genBody(state);
        window.open(
            `mailto:${CONTACT_EMAIL}?subject=${subject}&body=${body}`,
            '_blank'
        );
        setState(initState);
    };

    const submitDisabled: boolean = !state.name || !state.message;

    return (
        <Form>
            <Row>
                <Col md={6}>
                    <Form.Group className='mb-2'>
                        <Form.Label>{t('Name')}*</Form.Label>
                        <Form.Control
                            value={state.name}
                            onChange={onChange('name')}
                        />
                    </Form.Group>

                    <Form.Group className='mb-2'>
                        <Form.Label>
                            {t('Phone')} ({t('Optional')})
                        </Form.Label>
                        <Form.Control
                            value={state.phone}
                            onChange={onChange('phone')}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            {t('Region')} {t('Optional')}
                        </Form.Label>
                        <Form.Select
                            value={state.region}
                            onChange={onChange('region')}
                        >
                            <option></option>
                            {regions.map((region) => (
                                <option key={region}>{region}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group className='mb-2'>
                        <Form.Label>{t('Message')}*</Form.Label>
                        <Form.Control
                            as='textarea'
                            value={state.message}
                            onChange={onChange('message')}
                            className='text-start'
                            style={{ height: 123 }}
                        />
                    </Form.Group>

                    <Button
                        className='w-100'
                        variant='primary'
                        type='submit'
                        onClick={onSubmit}
                        disabled={submitDisabled}
                    >
                        {t('Send')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

interface Person {
    name: string;
    title: string;
    img?: string;
    email?: string;
    phone?: string;
}

const PersonContact: (person: Person) => JSX.Element = ({
    img,
    name,
    title,
    email,
    phone,
}) => (
    <Row className='d-flex justify-content-center text-center'>
        {img ? (
            <img
                alt='profilbild'
                src={img}
                style={{
                    width: 196,
                    height: 196,
                    objectFit: 'cover',
                }}
            />
        ) : (
            <div style={{ width: 196, height: 196, background: 'lightgray' }} />
        )}
        <Col
            md={6}
            className='d-flex flex-column justify-content-center pt-2'
            style={{ paddingLeft: 20 }}
        >
            <h3>
                <strong>{name}</strong>
            </h3>
            <h4 className='fw-light'>{title}</h4>
            {!!email && (
                <div>
                    <EmailIcon style={{ marginRight: 16 }} /> {email}
                </div>
            )}
            {!!phone && (
                <div>
                    <PhoneIcon style={{ marginRight: 16 }} /> {phone}
                </div>
            )}
        </Col>
    </Row>
);

const OurTeam = ({ data }: { data: Data | null }) => (
    <>
        <h2 style={{ marginBottom: 16 }}>
            <strong>Vårt team</strong>
        </h2>

        <hr
            className='mx-2 border border-secondary'
            style={{ marginBottom: 24 }}
        />

        <Row>
            {data?.team.map((person, index) => (
                <Col md={6} key={index}>
                    <PersonContact
                        img={person.image}
                        name={person.name || ''}
                        title={person.position || ''}
                        email={person.email}
                        phone={person.phone}
                    />
                </Col>
            ))}
        </Row>
    </>
);

const OurOffice = ({ data }: { data: Data | null }) => (
    <>
        <h2 style={{ marginBottom: 16 }}>
            <strong>Vårt kontor</strong>
        </h2>

        <hr
            className='mx-2 border border-secondary'
            style={{ marginBottom: 24 }}
        />

        <h3>
            <strong>{data?.address?.name || 'Dacke App AB'}</strong>
        </h3>

        <h3 className='fw-light'>{data?.address?.street || ''}</h3>


        <div>
            <EmailIcon style={{ marginRight: 16 }} /> {data?.address?.email || ''}
        </div>
        <div>
            <PhoneIcon style={{ marginRight: 16 }} /> {data?.address?.phone || ''}
        </div>
        {data?.address?.mapSrc && (
            <iframe
                title='office map'
                src={data?.address?.mapSrc}
                width={500}
                height={600}
                style={{ border: 0, paddingTop: 32 }}
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
            />
        )}
    </>
);

interface Props {}

export const ContactPage = (props: Props) => {
    const { t } = useTranslation();

    const [data, setData] = useState<Data | null>(null);

    useEffect(() => {
        getStaticPage('contact').then((data) => {
            setData(JSON.parse(data.data.content));
        });
    }, []);

    console.log(data);

    const sections: Section[] = [
        { id: '#formular', title: 'Kontakta oss' },
        { id: '#vart-team', title: 'Vårt team' },
        { id: '#vart-kontor', title: 'Vårt kontor' },
    ];

    return (
        <Page style={{ marginBottom: 112 }}>
            <Container>
                <Row>
                    <Col xxl={2} className='mt-4 my-4 d-none d-xxl-block'>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-1 fw-bold'
                        />
                    </Col>

                    <Col xxl={10}>
                        <Stack style={{ gap: 42 }}>
                            <div>
                                <SEO
                                    title={t('Contact us')}
                                    className='fw-light mb-3'
                                ></SEO>
                                <h3 id='formular' className='fw-light'>
                                    Tveka inte att höra av dig vid frågor eller
                                    funderingar.
                                </h3>
                            </div>

                            <Row style={{ marginTop: 42 }}>
                                <ContactForm />
                            </Row>

                            <Row id='vart-team' style={{ marginTop: 72 }}>
                                <OurTeam data={data} />
                            </Row>

                            <Row id='vart-kontor' style={{ marginTop: 72 }}>
                                <OurOffice data={data} />
                            </Row>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
