import axios from 'axios';
import IServerResponseType from '../types/IServerResponseType';

type IStaticPage = {
    slug: string;
    content: string;
};

export const getStaticPage = async (slug: string) => {
    const response = await axios.get<IServerResponseType<IStaticPage>>(
        `/api/static-pages/${slug}`
    );
    return response.data;
};
