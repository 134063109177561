import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { TableOfContents, Section } from './components/TableOfContents';
import { Page } from '../../../components/Layout/Page';
import { useTranslation } from 'react-i18next';
import { SEO } from '../../../components/SEO';
import { getStaticPage } from '../../../API/staticPages';

interface IntroProps {
    content: string;
}

const Intro: React.FC<IntroProps> = ({ content }) => (
    <p className='lh-md fs-4 fw-light pb-3'>{content}</p>
);

interface WhoAreWeProps {
    content: {
        title: string;
        paragraphs: string[];
    };
}

const WhoAreWe: React.FC<WhoAreWeProps> = ({ content }) => (
    <section>
        <h3 id='who-are-we' style={{ marginBottom: 24 }}>
            <strong>{content.title}</strong>
        </h3>
        {content.paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ))}
    </section>
);

interface PrivacyProtectionProps {
    content: {
        title: string;
        paragraph: string;
    };
}

const PrivacyProtection: React.FC<PrivacyProtectionProps> = ({ content }) => (
    <section>
        <h3 id='personal-data' style={{ marginBottom: 24 }}>
            <strong>{content.title}</strong>
        </h3>
        <p>{content.paragraph}</p>
    </section>
);

interface RegRightsProps {
    content: {
        title: string;
        rights: string[];
        contact: string;
    };
}

const RegRights: React.FC<RegRightsProps> = ({ content }) => (
    <section>
        <h3 style={{ marginBottom: 24 }}>
            <strong>{content.title}</strong>
        </h3>
        <ul>
            {content.rights.map((right, index) => (
                <li key={index}>{right}</li>
            ))}
        </ul>
        <p>{content.contact}</p>
    </section>
);

interface CookiesProps {
    content: {
        title: string;
        sections: {
            subtitle: string | null;
            text: string;
        }[];
    };
}

const Cookies: React.FC<CookiesProps> = ({ content }) => (
    <section>
        <h3 id='cookies' style={{ marginBottom: 24 }}>
            <strong>{content.title}</strong>
        </h3>
        {content.sections.map((section, index) => (
            <div key={index}>
                {section.subtitle && <h4 className='fw-light py-4'>{section.subtitle}</h4>}
                <p>{section.text}</p>
            </div>
        ))}
    </section>
);

interface PrivacyPolicyProps {}

interface Data {
    intro: string;
    whoAreWe: {
        title: string;
        paragraphs: string[];
    };
    privacyProtection: {
        title: string;
        paragraph: string;
    };
    regRights: {
        title: string;
        rights: string[];
        contact: string;
    };
    cookies: {
        title: string;
        sections: {
            subtitle: string | null;
            text: string;
        }[];
    };
}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {
    const { t } = useTranslation();

    const [data, setData] = useState<Data | null>(null);

    useEffect(() => {
        getStaticPage('privacy-policy')
            .then((response: any) => {
                setData(JSON.parse(response.data.content));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const sections: Section[] = [
        { id: '#who-are-we', title: t('Who are we?') },
        { id: '#personal-data', title: t('Personal data') },
        { id: '#cookies', title: t('Cookies') },
    ];

    if (!data) {
        return <Page></Page>;
    }

    return (
        <Page style={{ marginBottom: 112 }}>
            <Container>
                <Row>
                    <Col md={2}>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-4 d-none d-lg-block'
                        />
                    </Col>
                    <Col md={1} className='d-none d-xxl-block' />
                    <Col md={9}>
                        <Container fluid style={{ fontSize: 'larger' }}>
                            <Stack style={{ gap: 42 }}>
                                <div>
                                    <SEO
                                        title={t('Privacy policy')}
                                        className='fw-light lh-lg mb-3'
                                    ></SEO>
                                    <Intro content={data.intro} />
                                </div>

                                <WhoAreWe content={data.whoAreWe} />

                                <PrivacyProtection content={data.privacyProtection} />

                                <RegRights content={data.regRights} />

                                <Cookies content={data.cookies} />
                            </Stack>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
