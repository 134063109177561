import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Col,
    Container,
    OverlayTrigger,
    Stack,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { TableOfContents } from './components/TableOfContents';
import { Page } from '../../../components/Layout/Page';
import { SEO } from '../../../components/SEO';
import { useTranslation } from 'react-i18next';
import { getStaticPage } from '../../../API/staticPages';

interface Props {}

export const PartnerServicesPage = (props: Props) => {
    const { t } = useTranslation();
    const borderRadius = '0.5rem';

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        getStaticPage('partner-services').then((res) => {
            setData(JSON.parse(res.data.content));
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const Cards = data.map((item, index) => (
        <section key={`${item.name}-section`} id={item.name}>
            <h3 className='fw-bold' style={{ marginBottom: 24 }}>
                {item.title}
            </h3>

            <Row>
                {item.cards.map((item: any, index: number) => (
                    <Col
                        md={Math.floor(12 / item.length)}
                        sm
                        className='mb-4'
                        key={`${item.name}-${index}-card`}
                    >
                        <Card
                            className='border h-100 shadow'
                            style={{ borderRadius }}
                        >
                            <Card.Body
                                className='d-block text-truncate text-wrap'
                                style={{ maxHeight: 339 }}
                            >
                                {item.img && (
                                    <Card.Text className='text-center p-4'>
                                        <img
                                            src={item.img}
                                            className='h-100 img-fluid'
                                            style={{ maxHeight: 70 }}
                                            alt='Service logo'
                                        />
                                    </Card.Text>
                                )}
                                {item.title && (
                                    <Card.Title>{item.title}</Card.Title>
                                )}
                                <OverlayTrigger
                                    placement='right'
                                    delay={{ show: 50, hide: 100 }}
                                    overlay={
                                        <Tooltip className='fs-5'>
                                            {item.text}
                                        </Tooltip>
                                    }
                                >
                                    <Card.Text>{item.text}</Card.Text>
                                </OverlayTrigger>
                            </Card.Body>
                            {item.link && (
                                <Button
                                    variant='outline-primary'
                                    href={item.link}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='m-2'
                                >
                                    {t('Go to page')}
                                </Button>
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        </section>
    ));

    const sections = data.map(({ title, name }) => ({ title, id: `#${name}` }));

    return (
        <Page style={{ marginBottom: 112 }}>
            <Container>
                <Row>
                    <Col xxl={2} className='mt-4 my-4 d-none d-xxl-block'>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-1 fw-bold'
                        />
                    </Col>
                    <Col xxl={1} className='d-none d-xxl-block' />

                    <Col xxl={9}>
                        <Container fluid>
                            <Stack style={{ gap: 42 }}>
                                <div>
                                    <SEO
                                        title={t('Partner services')}
                                        className='fw-light lh-lh mb-3'
                                    ></SEO>
                                    <h4 className='fw-light'>
                                        Tveka inte att höra av dig vid frågor
                                        eller funderingar.
                                    </h4>
                                </div>
                                {Cards}
                            </Stack>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};
