import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ProductPriceProps {
    isSoldOut: boolean;
    price: number;
    symbol: string;
    comparisonPrice?: number;
    comparisonPriceSymbol?: string;
}

export const ProductPrice: React.FC<ProductPriceProps> = ({
    isSoldOut,
    price,
    symbol,
    comparisonPrice,
    comparisonPriceSymbol,
}) => {
    const { t } = useTranslation();

    return (
        <Card.Subtitle className='text-danger fs-2 pt-1 mb-3'>
            {isSoldOut ? (
                <b>{t('Sold out')}</b>
            ) : (
                <b>
                    {price ? `${price}:- /${symbol}` : t('Price upon request')}
                    <small className="fs-4">{(comparisonPrice && comparisonPriceSymbol) && (
                        ` (${comparisonPrice}:- /${comparisonPriceSymbol})`
                    )}</small>
                </b>
            )}
        </Card.Subtitle>
    );
};
