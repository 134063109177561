import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Stack, Table } from 'react-bootstrap';
import { TableOfContents, Section } from './components/TableOfContents';
import { ReactComponent as BoxesIcon } from '../../../assets/icones/boxes.svg';
import { Page } from '../../../components/Layout/Page';
import { SEO } from '../../../components/SEO';
import { getStaticPage } from '../../../API/staticPages';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AboutUsPage.scss';

type Data = {
    services: string[];
    forMoreInformation: {
        title: string;
        buttonText: string;
    };
    byUsersForUsers: {
        title: string;
        paragraphs: string[];
        list: string[];
    };
    packageInformation: {
        title: string;
        tableHeaders: string[];
        rows: (string | { text: string; subItems: string[] })[][];
    };
    contactUsFooter: {
        title: string;
        buttonText: string;
    };
};

const Services: React.FC<{ id: string; className: string; data: string[] }> = ({
    id,
    className,
    data,
}) => (
    <Row id={id} className={className}>
        <Col
            xs
            lg={6}
            className='d-none d-md-flex align-items-center justify-content-center'
        >
            <BoxesIcon />
        </Col>
        <Col xs lg={6}>
            <h2 style={{ marginBottom: 24 }}>
                <strong>Tjänster</strong>
            </h2>
            <ul>
                {data.map((item, index) => (
                    <li key={index}>
                        <h4 className='fw-light'>{item}</h4>
                    </li>
                ))}
            </ul>
        </Col>
    </Row>
);

const ForMoreInformation: React.FC<{ data: { title: string; buttonText: string } }> = ({
    data,
}) => (
    <div
        className='text-center d-flex flex-column align-items-center justify-content-center'
        style={{ background: '#003B4D0A', height: 200 }}
    >
        <h2
            style={{
                marginBottom: 24,
                paddingLeft: 74,
                paddingRight: 74,
            }}
        >
            <strong>{data.title}</strong>
        </h2>
        <Link to='/contact' className='btn btn-primary'>
            {data.buttonText}
        </Link>
    </div>
);

const ByUsersForUsers: React.FC<{ id: string; data: Data['byUsersForUsers'] }> = ({
    id,
    data,
}) => (
    <Row id={id}>
        <h2 style={{ marginBottom: 24 }}>
            <strong>{data.title}</strong>
        </h2>

        {data.paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ))}

        <ForMoreInformation data={{
            title: 'För mer information om våra tjänster, innehåller i paketet, avtal och priser',
            buttonText: 'Kontakta oss',
        }} />

        <Col xs md={6}>
            <p style={{ marginBottom: 24 }}>
                <strong>
                    Det är helheten som gör skillnaden och skapar resultatet -
                    att gå från ett linjärt – till cirkulärt byggande genom:
                </strong>
            </p>
        </Col>
        <ul>
            {data.list.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    </Row>
);

const PackageInformation: React.FC<{ id: string; data: Data['packageInformation'] }> = ({
    id,
    data,
}) => (
    <Row id={id}>
        <h2 style={{ marginBottom: 24 }}>
            <strong>{data.title}</strong>
        </h2>

        <Row className='align-items-center'>
            {data.tableHeaders.map((header, index) => (
                <Col xs md={3} className='text-center' key={index}>
                    <p className='rounded p-1' style={{ background: ['#E4EAEC', '#F9F1E6', '#ECE6F9'][index] || 'white', border: '1px solid black' }}>
                        <strong>{header}</strong>
                    </p>
                </Col>
            ))}
        </Row>
        <div
            style={{
                overflow: 'hidden',
                border: '1px solid black',
                borderRadius: '0.5rem',
            }}
        >
            <Table
                bordered
                hover
                style={{
                    tableLayout: 'fixed',
                    marginBottom: 0,
                    borderColor: 'black',
                }}
            >
                <tbody>
                    {data.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    className={cellIndex > 0 ? 'text-center' : ''}
                                    style={{
                                        background: ['#E4EAEC', '#F9F1E6', '#ECE6F9'][cellIndex - 1] || 'transparent',
                                        borderLeftWidth: cellIndex === 0 ? 0 : 1,
                                        borderRightWidth: cellIndex === row.length - 1 ? 0 : 1,
                                    }}
                                >
                                    {typeof cell === 'string' ? cell : (
                                        <div>
                                            <h5>{cell.text}</h5>
                                            {cell.subItems.map((subItem, subIndex) => (
                                                <h6 key={subIndex}>{subItem}</h6>
                                            ))}
                                        </div>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    </Row>
);

const ContactUsFooter: React.FC<{ data: Data['contactUsFooter'] }> = ({ data }) => (
    <div
        id='wrapper'
        className='text-white'
        style={{
            backgroundImage:
                "url('https://www.kompanjonen.se/wp-content/uploads/2022/02/pexels-pixabay-271667-scaled.jpg')",
            backgroundSize: 'cover',
            padding: '100px auto',
            marginTop: 112,
            marginLeft: -12,
            marginRight: -12,
            height: 400,
        }}
    >
        <Container
            fluid='sm'
            className='text-center d-flex flex-column align-items-center'
            style={{ margin: '116px auto', marginTop: 117 }}
        >
            <h2 style={{ maxWidth: 652, fontSize: 32, marginBottom: 42 }}>
                <strong>{data.title}</strong>
            </h2>
            <Link to='/contact' className='btn btn-secondary text-black'>
                {data.buttonText}
            </Link>
        </Container>
    </div>
);

interface Props {}

export const AboutUsPage = (props: Props) => {
    const { t } = useTranslation();

    const [data, setData] = useState<Data | null>(null);

    useEffect(() => {
        getStaticPage('about-us').then((data) => {
            setData(JSON.parse(data.data.content));
        });
    }, []);

    const sections: Section[] = [
        { id: '#tjanster', title: 'Tjänster' },
        {
            id: '#av-anvandare-for-anvandare',
            title: 'Av användare för användare',
        },
        { id: '#paketinformation', title: 'Paketinformation' },
    ];

    if (!data) return <Page />;

    return (
        <Page className='mb-0'>
            <Container>
                <Row className='jspace-between'>
                    <Col xxl={2} className='mt-4 my-4 d-none d-xxl-block'>
                        <TableOfContents
                            sections={sections}
                            className='position-fixed top-1 fw-bold'
                        />
                    </Col>
                    <Col xxl={1} className='d-none d-xxl-block' />

                    <Col xxl={9}>
                        <Stack style={{ gap: 42 }}>
                            <div>
                                <SEO
                                    title={t('About Dacke.online')}
                                    className='fw-light mb-3'
                                ></SEO>
                                <h4 className='fw-light'>
                                    Dacke.Online är ett lättarbetat digitalt
                                    hållbarhetsverktyg för inventering, köp och
                                    sälj av återbrukade produkter av hög
                                    kvalitet.
                                </h4>
                            </div>

                            <Services id='tjanster' className='py-4' data={data.services} />

                            <ByUsersForUsers id='av-anvandare-for-anvandare' data={data.byUsersForUsers} />

                            <PackageInformation id='paketinformation' data={data.packageInformation} />
                        </Stack>
                    </Col>
                </Row>
            </Container>

            <ContactUsFooter data={data.contactUsFooter} />
        </Page>
    );
};
